import React, { Dispatch } from "react";

export interface AuthContextType {
    token: string | null;
    userId: string | null;
    tokensLeft: number;
    expirationTime: number | null;
    anonymous: boolean;
}

export interface ActionType {
    type: "login" | "updateToken" | "updateTokensLeft" | "logout" | "updateAnonymous";
    loginPayload?: {
        userId: string;
        token: string;
        expirationTime: number;
        tokensLeft: number;
        anonymous: boolean;
    }
    updateTokenPayload?: {
        userId: string;
        token: string;
        expirationTime: number;
        anonymous: boolean;
    }
    updateTokensLeftPayload?: {
        tokensLeft: number;
    }
    updateAnonymous?: {
        anonymous: boolean;
    }
}

export const AuthContext = React.createContext<{ state: AuthContextType, dispatch: Dispatch<ActionType> }>({ state: { userId: null, token: null, expirationTime: null, tokensLeft: 0, anonymous: false }, dispatch: () => { } });
