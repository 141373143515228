// src/components/SvgDownloader.tsx

import React, { useState } from 'react';
import axios from 'axios';
import { tdrAlert } from '../../utils/utils';

const SvgDownloader: React.FC = () => {
    const [url, setUrl] = useState('');
    const [svgLinks, setSvgLinks] = useState<string[]>([]);
    const [message, setMessage] = useState<string>();
    let corsProxy = "http://localhost:8080/";
    const fetchSvgLinks = async (webpageUrl: string) => {
        try {
            const response = await axios.get(corsProxy + webpageUrl);
            const parser = new DOMParser();
            const doc = parser.parseFromString(response.data, 'text/html');
            const links = Array.from(doc.getElementsByTagName('a'))
                .map((anchor) => anchor.getAttribute('href'))
                .filter((href): href is string => href !== null && href.startsWith('/svg/'));
            setSvgLinks(links);
        } catch (error) {
            alert("" + error)
            console.error('Error fetching the webpage:', error);
        }
    };

    const downloadAllSvg = async () => {
        for (const link of svgLinks) {
            setMessage("Downloading " + link)
            await downloadSvg(link);
        }
    }

    const downloadSvg = async (svgUrl: string) => {
        try {
            //let svgdownload = `http://localhost:3001/proxy${svgUrl.replace("/svg/", "/download/")}.svg`;

            //            let svgdownload = corsProxy + (svgUrl.replace("/svg/", "/download/") + ".svg");

            let svgdownload = corsProxy + "https://svgrepo.com/download/" + (svgUrl.replace("/svg/", "") + ".svg");

            //            let svgdownload = corsProxy + "https://cdn.svgapi.com/vector/" + (svgUrl.replace("/svg/", "") + ".svg");
            const response = await axios.get(
                svgdownload, {
                responseType: 'blob',
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            });
            //            tdrAlert(svgdownload + " Status:" + response.status + " " + response.statusText)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', svgUrl.split('/').pop() + ".svg" || 'file.svg');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {

            tdrAlert('Error downloading the SVG file:' + error);
        }
    };

    const handleFetch = () => {
        if (url) {
            fetchSvgLinks(url);
        }
    };

    return (
        <div>
            <h1>SVG Downloader</h1>
            <input
                type="text"
                placeholder="Enter webpage URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
            />
            <button onClick={handleFetch}>Fetch SVG Links</button>
            <button onClick={() => downloadAllSvg()}>Download All</button>
            <div>
                <h2>SVG Links</h2>
                <ul>
                    {svgLinks.map((link, index) => (
                        <li key={index}>
                            <a href={link} target="_blank" rel="noopener noreferrer">
                                {link}
                            </a>
                            <button onClick={() => downloadSvg(link)}>Download</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SvgDownloader;
