
import { Routes, Route } from "react-router-dom";
import React from 'react';
import { useEffect, useState, lazy, Suspense } from "react";
import VideoState from "../src/context/video";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/globals.css";
import { ActionType, AuthContext, AuthContextType } from "./context/authcontext";
import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { tdrLog } from "./utils/utils";
import ProductPurchasev2 from "./pages/account/productpurchasev2";
import SvgDownloader from "./pages/utils/SVGDownloader";
import { AIGrabBGIds } from "./pages/writer/aigrabbgids";



const About = lazy(() => import('./pages/about'));
const Contacts = lazy(() => import('./pages/contacts'));
const Faqs = lazy(() => import('./pages/faqs'));;
const MainPage = lazy(() => import("./pages/main"));
const MainStart = lazy(() => import("./pages/mainstart"));
const Terms = lazy(() => import("./pages/terms"));
const Privacy = lazy(() => import("./pages/privacy"));
const PageNotFound = lazy(() => import("./pages/pagenotfound"));
const SlackIntegration = lazy(() => import("./pages/slackIntegration"));
const Models = lazy(() => import("./pages/account/models"));
const Login = lazy(() => import("./pages/login"));
const AIWriterMain = lazy(() => import("./pages/mainwriter"));
const AIParaphrase = lazy(() => import("./pages/mainparaphrase"));
const SlackChatGPT = lazy(() => import("./pages/slackchatgpt"));
const AIArtMain = lazy(() => import("./pages/mainart"));
const AIComprehendMain = lazy(() => import("./pages/maincomprehend"));
const ValidateEmail = lazy(() => import("./pages/account/validateEmail"));
const ChangePassword = lazy(() => import("./pages/account/changepassword"));

/* Landing pages */
const AIPhotoEnhancer = lazy(() => import("./pages/writer/landingpages/aiphotoenhancer"));
const AIUpscaler = lazy(() => import("./pages/writer/landingpages/aiupscaler"));
const AIBGRemover = lazy(() => import("./pages/writer/landingpages/aibgremove"));
const AIBGChanger = lazy(() => import("./pages/writer/landingpages/aibgreplace"));
const AIObjectRemove = lazy(() => import("./pages/writer/landingpages/aiobjectremove"));
const AIRoomDesign = lazy(() => import("./pages/writer/landingpages/airoomdesign"));
const AIVirtualStaging = lazy(() => import("./pages/writer/landingpages/aivirtualstaging"));
const AIHairstyles = lazy(() => import("./pages/writer/landingpages/aihairstyles"));
const AISketchToImage = lazy(() => import("./pages/writer/landingpages/aisketchtoimage"));
const AIProductPhoto = lazy(() => import("./pages/writer/landingpages/aiproductphoto"));
const AIMainTattoo = lazy(() => import("./pages/writer/landingpages/maintattoo"));
const AIMainClipArt = lazy(() => import("./pages/writer/landingpages/mainclipart"));
const AIMainTShirt = lazy(() => import("./pages/writer/landingpages/maintshirt"));
const AIMainFace = lazy(() => import("./pages/writer/landingpages/mainface"));
const AIMainAnimePFP = lazy(() => import("./pages/writer/landingpages/mainanime"));
const AIMainLogo = lazy(() => import("./pages/writer/landingpages/mainlogo"));
const AITattooList = lazy(() => import("./pages/writer/landingpages/aitattoolist"));
const AIBackgroundList = lazy(() => import("./pages/writer/landingpages/aibackgroundlist"));
const AIInteriorDesignList = lazy(() => import("./pages/writer/landingpages/aiinteriordesignlist"));
const AIHeadshot = lazy(() => import("./pages/writer/landingpages/aiheadshot"));
const AdRedirect = lazy(() => import("./pages/writer/landingpages/adRedirect"));


const AITopic = lazy(() =>
    import("./pages/writer/aitopic")
        .then(({ AITopic }) => ({ default: AITopic })),
);
const GoogleAnalytics = lazy(() =>
    import("./googleanalytics")
        .then(({ GoogleAnalytics }) => ({ default: GoogleAnalytics })),
);

const AIPhotoAutoGen = lazy(() =>
    import("./pages/writer/aiphotoautogen")
        .then(({ AIPhotoAutoGen }) => ({ default: AIPhotoAutoGen })),
);

const MemberProfile = lazy(() =>
    import("./pages/account/profile")
        .then(({ MemberProfile }) => ({ default: MemberProfile })),
);
const AIPhotoAdmin = lazy(() =>
    import("./pages/writer/aiphotoadmin")
        .then(({ AIPhotoAdmin }) => ({ default: AIPhotoAdmin })),
);

const AIPhotoDetail = lazy(() => import("./pages/writer/aiphotodetail")
    .then(({ AIPhotoDetail }) => ({ default: AIPhotoDetail })),);


const AIPhotoFeatured = lazy(() => import("./pages/writer/aiphotofeatured")
    .then(({ AIPhotoFeatured }) => ({ default: AIPhotoFeatured })),);

//import AIPhoto  from "./pages/writer/aiphoto";
//import Upscaler from "./pages/writer/beta/upscaler";
//import CannyEdge from "./pages/writer/beta/cannyedge";
//import Test from "./test";
//import AdminTasks from "./pages/admintasks";
//import AISegment from "./pages/writer/aisegment";
//import { AIPhotoDisplay } from "./pages/writer/aiphotodisplay";
//import Segment from "./components/segment/segment";

const AIWriter = lazy(() => import("./pages/writer/aiwriter"));
const AIMainMobileApp = lazy(() => import("./pages/mainmobileapp"));
const AIComprehend = lazy(() => import("./pages/writer/aicomprehend"));
const ProductPurchase = lazy(() => import("./pages/account/productpurchase"))

const AIPhoto = lazy(() =>
    import("./pages/writer/aiphoto")
        .then(({ AIPhoto }) => ({ default: AIPhoto })),
);

// Content type
const LandingHairstylePage = lazy(() => import("./pages/writer/landingpages/landinghairstylepage"))

const reducer = (state: AuthContextType, action: ActionType) => {
    switch (action.type) {
        case "login":
            if (action.loginPayload) {
                localStorage.setItem("userId", action.loginPayload.userId);
                localStorage.setItem("token", action.loginPayload.token);
                localStorage.setItem("expirationTime", action.loginPayload.expirationTime.toString());
                localStorage.setItem("tokensLeft", action.loginPayload.tokensLeft.toString());
                localStorage.setItem("anonymous", action.loginPayload.anonymous ? 'true' : 'false');
                return {
                    ...state,
                    userId: action.loginPayload.userId,
                    token: action.loginPayload.token,
                    expirationTime: action.loginPayload.expirationTime,
                    tokensLeft: action.loginPayload.tokensLeft,
                    anonymous: action.loginPayload.anonymous
                };
            } else {
                return state;
            }
        case "updateToken":
            if (action.updateTokenPayload) {
                localStorage.setItem("userId", action.updateTokenPayload.userId);
                localStorage.setItem("token", action.updateTokenPayload.token);
                localStorage.setItem("expirationTime", action.updateTokenPayload.expirationTime.toString());
                localStorage.setItem("anonymous", action.updateTokenPayload.anonymous ? 'true' : 'false');
                return {
                    ...state,
                    userId: action.updateTokenPayload.userId,
                    token: action.updateTokenPayload.token,
                    expirationTime: action.updateTokenPayload.expirationTime,
                    anonymous: action.updateTokenPayload.anonymous
                };
            } else {
                return state;
            }
        case "updateTokensLeft":
            if (action.updateTokensLeftPayload) {
                localStorage.setItem("tokensLeft", action.updateTokensLeftPayload.tokensLeft.toString());
                return {
                    ...state,
                    tokensLeft: action.updateTokensLeftPayload.tokensLeft
                };
            } else {
                return state;
            }
        case "updateAnonymous":
            if (action.updateAnonymous) {
                localStorage.setItem("anonymous", action.updateAnonymous.anonymous ? 'true' : 'false');
                return {
                    ...state,
                    anonymous: action.updateAnonymous.anonymous
                };
            } else {
                return state;
            }
        case "logout":
            localStorage.clear();
            return {
                ...state,
                userId: null,
                token: null,
                anonymous: true,
            };
        default:
            return state;
    }
};


export function App() {
    const [showRefreshPage, setShowRefreshPage] = useState<boolean>(false);
    const [currVersion, setCurrVersion] = useState("1.0");
    const [newerVersion, setNewerVersion] = useState("1.0");
    useEffect(() => {
        const interval = setInterval(async () => {
            // check if current version matches what's on the web server
            const meta = document.querySelector('meta[name="build-version"]') as any
            if (meta && meta.content) {
                try {
                    const currentVersion = meta.content as string
                    setCurrVersion(currentVersion);
                    // fetch version.txt
                    const response = await fetch("/version.txt", { cache: 'no-cache' })
                    if (response.status !== 200) {
                        // error
                        throw new Error("error fetching version")
                    }
                    const newVersion = await response.text()
                    setNewerVersion(newVersion);
                    tdrLog('refresh check - new version:' + newVersion + ', current version:' + currentVersion);

                    if (newVersion === "") {
                        return
                    }
                    if (newVersion !== currentVersion) {
                        setShowRefreshPage(true);
                        clearInterval(interval);
                    }
                } catch (err) {
                    // error checking
                    tdrLog('error checking for new version')
                }
            }
        }, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const expirationTimeString = localStorage.getItem('expirationTime');
    const anonymous = localStorage.getItem('anonymous') === 'true';
    const expirationTime = parseInt(expirationTimeString ? expirationTimeString : '0');
    const tokensLeftString = localStorage.getItem('tokensLeft');
    const tokensLeft = parseInt(tokensLeftString ? tokensLeftString : '0');
    const [state, dispatch] = React.useReducer(reducer, { userId: userId, token: token, expirationTime: expirationTime, tokensLeft: tokensLeft, anonymous: anonymous });

    const handleSnackClose = () => {
        setShowRefreshPage(false);
    }
    const handleSnackRefresh = () => {
        if (window) {
            window.location.reload();
        }
        handleSnackClose();
    }

    return (
        <AuthContext.Provider
            value={{
                state,
                dispatch
            }}>
            <VideoState>
                <div className="wsmenucontainer">
                    <Routes>
                        <Route path="/" element={<Suspense><MainPage /></Suspense>} />
                        <Route path="/start" element={<Suspense><MainStart /></Suspense>} />
                        <Route path="/about" element={<Suspense><About /></Suspense>} />
                        <Route path="/faqs" element={<Suspense><Faqs /></Suspense>} />
                        <Route path="/contacts" element={<Suspense><Contacts /></Suspense>} />
                        <Route path="/terms" element={<Suspense><Terms /></Suspense>} />
                        <Route path="/privacy" element={<Suspense><Privacy /></Suspense>} />
                        <Route path="/profile" element={<Suspense><MemberProfile /></Suspense>} />
                        <Route path="/ai-document-query-ask-answer" element={<Suspense><AIComprehendMain /></Suspense>} />



                        <Route path="/aesthetic-wallpapers-backgrounds-image" element=
                            {<Suspense><AIBackgroundList /></Suspense>} />
                        <Route path="/adredirect" element=
                            {<Suspense><AdRedirect /></Suspense>} />

                        <Route path="/home-decor-interior-design-styles-ideas" element=
                            {<Suspense><AIInteriorDesignList /></Suspense>} />

                        {/*** Windy Write Landing Pages */}
                        <Route path="/ai-writer-generator" element=
                            {<Suspense><AIWriterMain /></Suspense>} />
                        <Route path="/ai-paraphrasing-rewording-tool" element={<Suspense><AIParaphrase /></Suspense>} />

                        {/*** Windy Art Landing Pages */}
                        <Route path="/ai-art-image-generator" element={<Suspense><AIArtMain /></Suspense>} />
                        <Route path="/ai-generated-art/:page" element={<Suspense><AIPhotoFeatured /></Suspense>} />
                        <Route path="/pic/:keyword" element={<Suspense><AITopic /></Suspense>} />
                        <Route path="/aigrabbgids/:keyword" element={<AIGrabBGIds />} />
                        <Route path="/ai-pic/:seotitle/:id" element={<Suspense><AIPhotoDetail /></Suspense>} />
                        <Route path="/clip/:seotitle/:id" element={<Suspense><AIPhotoDetail /></Suspense>} />

                        {/**** Landing pages ****/}
                        <Route path="/ai-upscaler" element={<Suspense><AIUpscaler /></Suspense>} />
                        <Route path="/ai-photo-enhancer" element={<Suspense><AIPhotoEnhancer /></Suspense>} />
                        <Route path="/background-remover-ai" element={<Suspense><AIBGRemover /></Suspense>} />
                        <Route path="/ai-room-design-generator" element={<Suspense><AIRoomDesign /></Suspense>} />
                        <Route path="/ai-virtual-home-staging-realtors" element={<Suspense><AIVirtualStaging /></Suspense>} />
                        <Route path="/background-changer-ai" element={<Suspense><AIBGChanger /></Suspense>} />
                        <Route path="/ai-object-remover" element={<Suspense><AIObjectRemove /></Suspense>} />
                        <Route path="/ai-hairstyle-try-on" element={<Suspense><AIHairstyles /></Suspense>} />
                        <Route path="/sketch-to-image-ai" element={<Suspense><AISketchToImage /></Suspense>} />
                        <Route path="/ai-product-photography" element={<Suspense><AIProductPhoto /></Suspense>} />
                        <Route path="/ai-photo-editor" element={<Suspense><AIMainMobileApp /></Suspense>} />
                        <Route path="/ai-tattoo-design-generator" element={<Suspense><AIMainTattoo /></Suspense>} />
                        <Route path="/ai-clipart-image-generator" element={<Suspense><AIMainClipArt /></Suspense>} />
                        <Route path="/ai-t-shirt-design-generator-maker" element={<Suspense><AIMainTShirt /></Suspense>} />
                        <Route path="/ai-face-generator-maker" element={<Suspense><AIMainFace /></Suspense>} />
                        <Route path="/ai-anime-pfp-generator" element={<Suspense><AIMainAnimePFP /></Suspense>} />
                        <Route path="/ai-logo-maker-generator" element={<Suspense><AIMainLogo /></Suspense>} />
                        <Route path="/tattoo-ideas-design-men-women" element=
                            {<Suspense><AITattooList /></Suspense>} />
                        <Route path="/ai-professional-headshots" element=
                            {<Suspense><AIHeadshot /></Suspense>} />

                        {/**** Landing pages end ****/}
                        {/** Landing pages with more content type nature */}
                        <Route path="/women-hairstyle/:id" element={<Suspense><LandingHairstylePage /></Suspense>} />
                        <Route path="/men-hairstyle/:id" element={<Suspense><LandingHairstylePage /></Suspense>} />
                        <Route path="/models" element={<Suspense><Models /></Suspense>} />
                        <Route path="/slack-chatgpt" element={<Suspense><SlackChatGPT /></Suspense>} />

                        <Route path="/aicomprehend" element={<Suspense><AIComprehend /></Suspense>} />
                        <Route path="/aiwriter" element={<Suspense><AIWriter /></Suspense>} />
                        <Route path="/plan" element={<Suspense><ProductPurchase /></Suspense>} />

                        <Route path="/premium" element={<Suspense><ProductPurchasev2 /></Suspense>} />
                        <Route path="/ai-art" element={<Suspense><AIPhoto /></Suspense>} />
                        <Route path="/aiphotoadminv1" element={<Suspense><AIPhotoAdmin /></Suspense>} />
                        <Route path="/aiphotoadmin" element={<Suspense><AIPhotoAutoGen /></Suspense>} />

                        {/**** Beta pages start
                        <Route path="/upscaler" element={<Upscaler />} />
                        <Route path="/canny" element={<CannyEdge />} />                        
                        <Route path="/test" element={<Test />} />
                        <Route path="/adminTasks" element={<AdminTasks />} />

                       Beta pages end ****/}
                        {/*
                        <Route path="/segment" element={<Segment />} />
        <Route path="/ai-segment" element={<AISegment />} />
                                <Route path="/ai-image-editor" element={<AIImageEditor />} />
        */}
                        <Route path="/login" element={<Suspense><Login /></Suspense>} />
                        <Route path="/validateEmail" element={<Suspense><ValidateEmail /></Suspense>} />
                        <Route path="/resetPassword" element={<Suspense><ChangePassword /></Suspense>} />
                        <Route path="/slackIntegration" element={<Suspense><SlackIntegration /></Suspense>} />
                        <Route path="/pagenotfound" element={<Suspense><PageNotFound /></Suspense>} />
                        <Route path="*" element={<Suspense><PageNotFound /></Suspense>} />
                        <Route path="/svgdownloader" element={<SvgDownloader />} />
                    </Routes >

                    <Snackbar
                        open={showRefreshPage}
                        autoHideDuration={6 * 60 * 1000}
                        message={process.env["REACT_APP_ENV"] === "production" ? "The web site has been updated. Tap refresh on your browser to refresh this page to get the latest version." : `The web site has been updated to ${newerVersion} from ${currVersion}. Tap refresh on your browser to refresh this page to get the latest version.`}

                    >
                        <Alert severity="info" sx={{ width: '100%' }} action={
                            <React.Fragment>
                                <Button color={'warning'} size="small" onClick={handleSnackRefresh}>
                                    Refresh
                                </Button>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    sx={{ p: 0.5 }}
                                    onClick={handleSnackClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </React.Fragment>
                        }>
                            The web site has been updated. Tap refresh on your browser to refresh this page to get the latest version.  </Alert>
                    </Snackbar>
                </div >
            </VideoState >
            <Suspense>
                <GoogleAnalytics />
            </Suspense>

        </AuthContext.Provider >
    );
}

