import { EventEmitter } from 'events';
import { tdrLog } from './utils';

export { APIObject }

class APIObject extends EventEmitter {

    newError(errorDescription: string, errorName: string): Error {
        const err = new Error(errorDescription);
        err.name = errorName;
        return err;
    }

    log(msg: string) {
        tdrLog(msg);
    }

    error(msg: string, err: any) {
        console.error(msg + ':' + err.message);

    }

    async delay(delayInMilliseconds: number): Promise<void> {
        return new Promise((resolve, _reject) => {
            setTimeout(() => {
                resolve();
            }, delayInMilliseconds);
        });
    }
}