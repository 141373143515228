


import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Paper, Radio, RadioGroup } from "@mui/material";
import { useAPI } from "../../utils/useAPI";
import { RequireAuth, useUser } from "../../utils/useUser";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Stripe } from "@stripe/stripe-js/";
import { Elements } from "@stripe/react-stripe-js";
import ProductCheckout from "../account/productCheckout";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as apiproto from "../../apiproto";
import { DialogMessageBox } from "../../components/DialogMessageBox";
import { sendEvent, sendPurchaseEvent, tdrAlert } from "../../utils/utils";
import LoadingButton from "@mui/lab/LoadingButton";
import PageTitle from "../../layout/PageTitle";
import Layout from "../../layout/Layout";
import RefreshIcon from '@mui/icons-material/Refresh';
import React from 'react';
import { Typography } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import BrushIcon from '@mui/icons-material/Brush';
import LayersIcon from '@mui/icons-material/Layers';
import PhotoIcon from '@mui/icons-material/Photo';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ColorLensIcon from '@mui/icons-material/ColorLens';

const FeatureList = () => {
    const iconStyle = { verticalAlign: 'middle', marginRight: '8px', color: '#ff8c00' };

    return (


        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <Typography variant="h6">
                <StarsIcon style={iconStyle} />
                <b>Unlock all features and styles</b> for headshots, hairstyles, AI art generation and more!
            </Typography>
            <Typography variant="h6" style={{ marginTop: '16px' }}>
                <RocketLaunchIcon style={iconStyle} />
                Increase your productivity, creativity and design with Windy AI as your <b>co-pilot</b>.
            </Typography>
            <Typography variant="h6" style={{ marginTop: '16px' }}>
                <LayersIcon style={iconStyle} />
                Access to all our powerful AI models
            </Typography>
            <Typography variant="h6" style={{ marginTop: '16px' }}>
                <PhotoIcon style={iconStyle} />
                Access to <b>background removals, background changers, text editors, AI upscalers</b> and more!
            </Typography>
            <Typography variant="h6" style={{ marginTop: '16px' }}>
                <ColorLensIcon style={iconStyle} />
                Generate up to 200+ AI images
            </Typography>
            <Typography variant="h6" style={{ marginTop: '16px' }}>
                <AddPhotoAlternateIcon style={iconStyle} />
                <b>Photo Editor and designer</b> always free to use. Use AI to make posters, brochures, wallpaper and more...
            </Typography>
            <Typography variant="h6" style={{ marginTop: '16px' }}>
                <MonetizationOnIcon style={iconStyle} />
                <b>No subscription.</b> Just top up when you need.
            </Typography>
        </div>
    );
};


type StripeKeys = {
    clientSecret: string;
    publishableKey: string;
};
const ProductPurchase = () => {
    const api = useAPI();
    const user = useUser();
    const [search] = useSearchParams();
    const [errorMessage, setErrorMessage] = useState<string | undefined | null>(null);
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
    const [stripeKeys, setStripeKeys] = useState<StripeKeys>({ clientSecret: "", publishableKey: "" });
    const [existingPayment, setExistingPayment] = useState("");
    const [products, setProducts] = useState<apiproto.api.IPlanInfo[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<apiproto.api.IPlanInfo>();
    const [autoRecharge, setAutoRecharge] = useState(true);
    const [accountType, setAccountType] = useState("individual");
    const [prevSavedData, setPrevSavedData] = useState({ team: "individual", plan: "" });
    const [planRadioValue, setPlanRadioValue] = useState("topup_130k")
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true });
    const [updatePaymentInfo, setUpdatePaymentInfo] = useState(false);
    const [userInfo, setUserInfo] = useState<apiproto.api.IUser | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [readyToRender, setReadyToRender] = useState(false);
    const [refreshTime, setRefreshTime] = useState(0);
    const redirect = new URLSearchParams(search).get('redirect');

    const handleRechargeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAutoRecharge(event.target.checked);
    };
    /*
        const handleAccountTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setAccountType((event.target as HTMLInputElement).value);
        };
    */
    const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlanRadioValue((event.target as HTMLInputElement).value);
    };
    const paymentSuccess = () => {
        (async () => {
            const value = ((selectedPlan && selectedPlan.amount) ? selectedPlan?.amount : 0)
            const planId = ((selectedPlan && selectedPlan.planId) ? selectedPlan?.planId : "")
            sendEvent('Purchase', 'Top up', planId, value);
            sendEvent('Upgrade', 'UpgradeSuccess', planId, value);
            sendEvent('Upgrade', 'UpgradeSuccess' + planId, planId, value);
            const now = Math.floor(Date.now() / 1000);
            sendPurchaseEvent(now.toString(), value, planId);
            setUpdatePaymentInfo(false);
            updateUser();
            setupPayment();
        })();
    }

    const refreshToken = () => {
        setRefreshTime(refreshTime + 1);
    }

    const updateUser = () => {
        (async () => {
            setIsLoading(true);
            try {

                const userInfo = await api.getUser({ credentials: { slackUser: {} } }, { userId: user.userId });

                if (userInfo && userInfo.user && accountType && selectedPlan && selectedPlan.planId && userInfo !== null) {

                    const tmpuser = userInfo.user;
                    if (tmpuser.credentials && tmpuser.credentials.length > 0 && tmpuser.credentials![0].slackUser) {
                        tmpuser.credentials![0].slackUser!.teamPaymentUser = (accountType === "team" ? true : false);
                    }
                    if (selectedPlan.planId === "0d") {
                        tmpuser.topupPlanId = "";
                    }
                    else {
                        tmpuser.topupPlanId = selectedPlan.planId;
                    }
                    await api.updateUser({ topupPlanId: true }, tmpuser);
                    if (tmpuser.credentials && tmpuser.credentials.length > 0 && tmpuser.credentials![0].slackUser) {
                        await api.updateSlackUser({ teamPaymentUser: true }, tmpuser.credentials![0].slackUser!);
                    }
                    setUserInfo(tmpuser);
                    setIsLoading(false);
                    setPrevSavedData({ team: accountType, plan: tmpuser.topupPlanId });
                    setDialogMessage({ title: 'Plan Update Success', message: "You have successfully update changes to your plan. Your payment will only be charged and AI units topped up when your AI units falls below a threshold.", meta: { msg: "paymentSuccess" }, confirm: false });
                }
            } catch (e: any) {
                if (e.name === "TEAM_ADMIN_EXISTED") {
                    setDialogMessage({ title: 'Plan Update Error', message: "There can only be one person (the admin) who top up for the team in a workspace. Please contact the admin to switch their account plan to Individual. Meanwhile, your settings will now be set to Individual.", meta: {}, confirm: false });

                }
                else {
                    setDialogMessage({ title: 'Plan update error', message: "An error occurred during the purchase of your selected plan.", meta: {}, confirm: false });
                    tdrAlert(e.name + " " + e.message)
                }
            }
            setIsLoading(false);
            return null;
        })();
    }

    const paymentCancelled = () => {
        if (!existingPayment) {
            // This is a new payment and so if user hits cancel, it should just return
            cancelWindow();
            return;
        }
        setUpdatePaymentInfo(false);
        sendEvent('Upgrade', 'UpgradeCancel', user.tokensLeft + "");

        //        closeWindow();
    }

    const navigate = useNavigate();
    const cancelWindow = () => {
        // This cancel is different since it comes from cancel
        if (redirect === "editor") {
            // Special handling: This is flutter app so can't just go use router navigate
            let host = window.location.origin;
            if (host.indexOf("dev.shared") > 0) {
                // since dev does not have route to editor
                host = "https://chat.timepiper.com";
            }
            // Editor is not part of navigation route and hence a window.location.href
            const url = host + "/editor";
            window.location.href = url;
            return;
        }
        else {
            navigate(-1);
        }
    }
    const closeWindow = () => {

        setDialogMessage({ title: '', message: "", meta: {}, confirm: false });
        sendEvent('Upgrade', 'UpgradeClose', user.tokensLeft + "");
        const message: any = dialogMessage.meta;
        if (message && message.msg === "paymentSuccess") {
            // This is a success so we are done.
            if (document.referrer !== null && document.referrer.indexOf("/editor") > 0) {
                // This handles when click comes from flutter editor.
                // We could technically close the tab too? instead of refreshing since
                // the other tab with editor may still be there?
                // window.close();
                window.location.href = document.referrer;
                return;
            }
            else if (redirect === "editor") {
                // Special handling: This is flutter app so can't just go use router navigate
                let host = window.location.origin;
                if (host.indexOf("dev.shared") > 0) {
                    // since dev does not have route to editor
                    host = "https://chat.timepiper.com";
                }
                // Editor is not part of navigation route and hence a window.location.href
                const url = host + "/editor";
                window.location.href = url;
                return;
            }
            else {
                window.location.href = "https://windybot.com/ai-art";
                //                navigate(-1)
            }
        }
        else {
            navigate(-1)
        }

        //        navigate("/profile");
    }

    const confirmWindow = () => {
    }

    const handleSubmit = () => {

    }

    const updatePaymentUI = () => {
        setUpdatePaymentInfo(true);
    }

    const setupPayment = async () => {
        try {
            if (user !== null && user.authenticated) {
                // Only need to set up if user is authenticated                
                const result = await api.updatePayment()
                if (result !== null && result.publishableKey && result.publishableKey?.length > 0) {
                    const stripeKeysResult = { clientSecret: result.paymentClientSecret!, publishableKey: result.publishableKey! };
                    setStripeKeys(stripeKeysResult)
                    if (result.paymentClientSecret && result.paymentClientSecret.length > 0 && stripeKeysResult.publishableKey.length > 0) {
                        setStripePromise(loadStripe
                            (stripeKeysResult.publishableKey!, {
                            }));
                    }
                    if (result !== null && result.existingPaymentDescription && result.existingPaymentDescription.length > 0) {
                        setExistingPayment(result.existingPaymentDescription);
                    }
                    else {
                        setExistingPayment("");
                    }
                }
                setReadyToRender(true);
            }
        }
        catch (err: any) {
            tdrAlert(JSON.stringify(err));
        }

    }
    useEffect(() => {
        if (user.authenticated) {
            setErrorMessage("");
            setupPayment();
            setIsLoading(true);
            // This is where we get user information to update payment, product plan, recharge choice, etc
            (async () => {
                const productInfo = await api.getAvailablePlans();
                if (productInfo && productInfo !== null && productInfo.plans) {
                    if (existingPayment.length > 0) {
                        productInfo.plans.push({ planId: "0d", amount: 0, tokens: 0 })
                    }
                    productInfo.plans.sort((a, b) => (
                        (a.amount === null || b.amount === null || a.amount === undefined || b.amount === undefined) ? 0 :
                            ((a.amount > b.amount) ? 1 : ((b.amount > a.amount) ? -1 : 0))));
                    setProducts(productInfo.plans);
                    //                    tdrAlert(JSON.stringify(productInfo.plans))
                    setSelectedPlan(productInfo.plans[0])
                    if (productInfo.plans[0].planId) {
                        setPlanRadioValue(productInfo.plans[0].planId)
                    }
                }

                const userInfo = await api.getUser({ credentials: { slackUser: {} } }, { userId: user.userId });
                if (userInfo && userInfo.user) {
                    setUserInfo(userInfo.user);
                }
                let team = "individual";
                setAccountType("individual")

                if (userInfo.user) {
                    if (userInfo.user.credentials && userInfo.user.credentials.length > 0 && userInfo.user.credentials![0].slackUser && userInfo.user.credentials![0].slackUser!.teamPaymentUser) {
                        setAccountType("team")
                        team = "team";
                    }
                    else {
                        setAccountType("individual")
                    }

                    let planid = userInfo.user.topupPlanId;

                    if (planid === "") {
                        // This is kind of hack unless server supports some 0 token plan and not just blank planid
                        planid = "0d";
                        if (productInfo.plans && productInfo.plans.length > 2) {
                            // IF no plan we set default plan to the second plan which is $10
                            planid = productInfo.plans[0].planId;
                        }
                    }

                    if (planid && planid !== null) {
                        setPrevSavedData({ team: team, plan: planid });
                        const plan = productInfo.plans?.filter((obj: apiproto.api.IPlanInfo) => obj.planId === planid)
                        if (plan && plan.length > 0) {
                            setSelectedPlan(plan[0]);
                            if (plan[0].planId) {

                                setPlanRadioValue(plan[0].planId)
                            }

                        }
                    }

                    /** A/B Testing
                     * Simplify choice
                     * 
                     * 
                     */
                    if (userInfo.user && !userInfo.user.isPaid) {

                    }
                }

                setIsLoading(false);
            })();
        }

    }, [user.userId, user.authenticated, refreshTime]);

    useEffect(() => {

        const chosenProduct = products.filter((obj: apiproto.api.IPlanInfo) => obj.planId === planRadioValue);
        if (chosenProduct && chosenProduct.length > 0) {
            setSelectedPlan(chosenProduct[0]);
        }
    }, [planRadioValue]);

    useEffect(() => {
        // Send signal that upgrade was shown
        sendEvent('Upgrade', 'UpgradeShown', user.tokensLeft + "");
        //sendPurchaseEvent("testid2", 1, "testplan2");

    }, []);



    return (
        <div>
            < DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Cancel" : "Dismiss"} onCancel={closeWindow} onConfirm={confirmWindow} visible={dialogMessage.title.length > 0} />
            <RequireAuth disAllowAnonymous={true}>
                <Layout headerBtn footer={3} hideHeader={redirect === "editor" ? true : false}>
                    <PageTitle pageTitle="Billing Plan for AI Units" />
                    {readyToRender ?
                        <ValidatorForm id="payment-form" onSubmit={handleSubmit}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="800px"
                                marginTop="60px"
                                padding={1}
                            >
                                <div className="form-group">
                                    <br />
                                    <Paper elevation={6} sx={{ maxWidth: 1200, width: "100%", padding: '10px' }}>
                                        <p style={{ textAlign: 'right', display: 'none' }}>
                                            {isLoading ? "" : (userInfo?.tokensLeft ? (Number((+userInfo?.tokensLeft)) / 10000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }) : "0")} AI Units Left
                                            <LoadingButton
                                                sx={{ mb: 1 }}
                                                size="large"
                                                variant="text"
                                                loading={isLoading}
                                                onClick={refreshToken}
                                                startIcon={<RefreshIcon />} />
                                        </p>
                                        <Row style={{ padding: 20 }}>
                                            {(userInfo && !userInfo.isPaid) ?
                                                <Col md="6" style={{ padding: "0px 30px 0px 30px" }} >
                                                    <h3 style={{ fontSize: "1.5rem" }}>Upgrade to Pro for {((selectedPlan && selectedPlan.amount) ? "US" + selectedPlan.amount.toLocaleString("en-US", { style: "currency", currency: "USD" }) : "")}</h3>
                                                    <FeatureList />
                                                </Col> :
                                                <Col md="6" style={{ padding: "0px 30px 0px 30px" }} >

                                                    <Row>
                                                        <Col md="12">
                                                            {/* Turn this off for now
                                                        <b>Choose account to top up</b>
                                                        <br />
                                                        <FormControl>
                                                            <RadioGroup
                                                                aria-labelledby="billing radio group"
                                                                name="controlled-radio-buttons-group"
                                                                value={accountType}
                                                                onChange={handleAccountTypeChange}
                                                            >

                                                                <FormControlLabel control={<Radio />} label="Individual - For personal use." value={"individual"} />

                                                                <FormControlLabel control={<Radio />} label="Team - Everyone in the workspace can use it." value={"team"} />

                                                            </RadioGroup>
                                                        </FormControl>
                                                        <hr />
                    */}
                                                            <FormControl style={{ width: "100%" }}>
                                                                <RadioGroup
                                                                    aria-labelledby="billing radio group"
                                                                    name="controlled-radio-buttons-group"
                                                                    value={planRadioValue}
                                                                    onChange={handlePlanChange}
                                                                >
                                                                    {products.map((product) => (
                                                                        product.planId === "0d" ?
                                                                            <Paper elevation={6} sx={{ padding: '18px', marginBottom: '15px', borderRadius: 2, border: `${planRadioValue === product.planId ? "4px solid #00aa00" : "1px solid #555555"}`, fontSize: "1.0rem" }}>

                                                                                <Row>
                                                                                    <Col>
                                                                                        <FormControlLabel key={product.planId} control={<Radio />} label={<div style={{

                                                                                            display: "flex", alignItems: "center",
                                                                                            padding: "15px 0px 15px 0px"
                                                                                        }}><div>
                                                                                                <div style={{ fontSize: "1.0rem", }}>
                                                                                                    No top up
                                                                                                </div>
                                                                                                <div style={{ fontSize: "0.75rem", color: "#555555", marginTop: "5px" }}>
                                                                                                    When your AI Units run out, your balance will not be automatically top up. You will need to select a plan again.
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>} value="0d" />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Paper>
                                                                            :
                                                                            <Paper elevation={6} sx={{ padding: '10px 10px 0px 10px', marginBottom: '15px', borderRadius: 2, border: `${planRadioValue === product.planId ? "4px solid #00aa00" : "1px solid #555555"}`, fontSize: "1.0rem" }} key={product.planId + "paper"}>

                                                                                <Row>
                                                                                    <Col xs="8" style={{
                                                                                        display: "flex", alignItems: "center",

                                                                                    }}>
                                                                                        <div key={product.planId} >
                                                                                            <FormControlLabel
                                                                                                control={<Radio />} label={<div style={{

                                                                                                    display: "flex", alignItems: "center",
                                                                                                    padding: "0px 0px 8px 0px"
                                                                                                }}>
                                                                                                    <div>
                                                                                                        <div style={{ fontSize: "1.0rem", }}>
                                                                                                            {(product.tokens ? (+product.tokens / 50000) : "")} image generations

                                                                                                        </div>

                                                                                                        <div style={{ fontSize: "0.75rem", color: "#555555", marginTop: "5px" }}>
                                                                                                            {(product.tokens ? (+product.tokens / 50000) : "")} generations
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>} value={product.planId} />

                                                                                        </div>

                                                                                    </Col>
                                                                                    <Col xs="4" style={{
                                                                                        fontSize: "1.1rem",
                                                                                        fontWeight: "500",
                                                                                        textAlign: "right",
                                                                                        display: "flex", alignItems: "center",
                                                                                        alignContent: "right",
                                                                                        justifyContent: "right",
                                                                                    }}>
                                                                                        <div>
                                                                                            {(product.amount ? "USD" + product.amount.toLocaleString("en-US", { style: "currency", currency: "USD" }) : "")}
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>

                                                                            </Paper>
                                                                    ))}
                                                                </RadioGroup>
                                                            </FormControl>
                                                            <br /><br />
                                                            {(selectedPlan?.amount && selectedPlan.tokens) ?
                                                                <div style={{ marginBottom: '15px' }}>
                                                                    {existingPayment.length > 0 ? <div><b>{existingPayment}</b> will be charged <b>{selectedPlan.amount.toLocaleString("en-US", { style: "currency", currency: "USD" })}</b> to top up <b>{(+selectedPlan?.tokens / 10000).toLocaleString()}</b> AI Units when it falls below a threshold. You can {!updatePaymentInfo && (<Link style={{ textDecoration: "underline" }} to="#" onClick={updatePaymentUI}>update payment information</Link>)} if your payment information has changed.</div> : <div> You will be charged <b>{selectedPlan.amount.toLocaleString("en-US", { style: "currency", currency: "USD" })}</b> to top up <b>{(+selectedPlan?.tokens / 10000).toLocaleString()}</b> AI Units. AI Units are used for various AI related services as it uses GPU servers that cost to run.</div>}
                                                                </div> :
                                                                (selectedPlan?.planId === "0d" ?
                                                                    <div>
                                                                        Your AI Units will not be topped up when your balance runs to zero. This could cause a disruption to your use of the service. We recommend that you set a minimum top-up amount.
                                                                    </div> :
                                                                    <div>
                                                                        You can top up your AI Units balance by adding a credit card to your payment information.
                                                                    </div>)
                                                            }
                                                            <div style={{ display: 'none' }}>
                                                                <hr />
                                                                <FormControlLabel control={<Checkbox checked={autoRecharge} onChange={handleRechargeChange} />} label="Automatic Recharge" />
                                                                <br />
                                                                {(autoRecharge && selectedPlan && selectedPlan.amount && selectedPlan?.tokens) ? <div>                                                When the AI Unit reached below a threshold, I agree that my credit card will be billed at {selectedPlan.amount.toLocaleString("en-US", { style: "currency", currency: "USD" })} to add an additional {selectedPlan.tokens.toLocaleString()} AI Units to my account so that there will be no disruption to continue using the service. </div>
                                                                    :
                                                                    ""
                                                                }

                                                                <br />
                                                                <hr />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>}
                                            {(!(existingPayment && !updatePaymentInfo) && readyToRender) ?
                                                < Col md="6" style={{ border: '1px solid gray', padding: 20 }} >
                                                    <Row>
                                                        <Col>
                                                            <div className="form-group form-input">
                                                                {errorMessage && (
                                                                    <p className="form-error">
                                                                        Error:{errorMessage}
                                                                    </p>)
                                                                }
                                                                <Elements options={{
                                                                    clientSecret: stripeKeys.clientSecret, appearance: {
                                                                        theme: 'stripe', variables: {
                                                                            colorPrimary: '#0570de',
                                                                            colorBackground: '#ffffff',
                                                                            colorText: '#30313d',
                                                                            colorDanger: '#df1b41',
                                                                            fontFamily: 'Ideal Sans, system-ui, sans-serif',
                                                                            fontSizeBase: '18px',
                                                                            spacingUnit: '4px',
                                                                            borderRadius: '4px',
                                                                            // See all possible variables below
                                                                        }
                                                                    }

                                                                }} stripe={stripePromise} key={stripeKeys.clientSecret}>
                                                                    <ProductCheckout planSelected={selectedPlan} update={updatePaymentInfo} stripeKeys={stripeKeys} existingPayment={existingPayment} paymentCancel={paymentCancelled} paymentSuccess={paymentSuccess} />
                                                                </Elements>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </Col> :
                                                < Col md="6" style={{ textAlign: 'center', margin: 'auto' }} >
                                                    <img alt="Top up" width="280" src="/images/lightbulb.jpg" />

                                                </Col>

                                            }



                                        </Row>
                                        {(existingPayment && !updatePaymentInfo) &&
                                            <Row>
                                                <Col>
                                                    <hr />
                                                    <Button
                                                        sx={{ float: 'right', mr: 2 }}
                                                        size="large"
                                                        variant="text"
                                                        onClick={cancelWindow}
                                                        disabled={isLoading}>
                                                        Cancel
                                                    </Button>

                                                    <LoadingButton
                                                        sx={{ float: 'right', mr: 2, mb: 2 }}
                                                        size="large"
                                                        variant="contained"
                                                        loading={isLoading}
                                                        onClick={updateUser}
                                                        disabled={existingPayment.length > 0 && prevSavedData.team === accountType && (selectedPlan && prevSavedData.plan === selectedPlan.planId)}
                                                        hidden={false}>
                                                        {existingPayment ? "Save Changes" : "Save"}
                                                    </LoadingButton>
                                                    <br /><br />
                                                </Col>
                                            </Row>
                                        }
                                        <br />

                                    </Paper>
                                    <br /><br />
                                </div>
                                <br /><br /><br />

                            </Box>
                        </ValidatorForm> :
                        <div id="loading-main">
                            <div id="loading-anim">
                                <div className="cssload-loading">
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                    <i></i>
                                </div>
                            </div>
                        </div>
                    }
                </Layout>
            </RequireAuth>
        </div >
    )
}

export default ProductPurchase;
