import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from "./utils/scrolltotop";
import { HelmetProvider } from 'react-helmet-async';

const baseUrl = process.env['REACT_APP_BASEURL'];

const rootElement = document.getElementById("root");

// WARNING: Do not switch to hydrate root as it will cause tree mismatch
if (rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<React.StrictMode>

    <BrowserRouter basename={baseUrl}>
      <ScrollToTop />
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>, rootElement);
} else {
  ReactDOM.render(<React.StrictMode>

    <BrowserRouter basename={baseUrl}>
      <ScrollToTop />
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
