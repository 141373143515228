import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/authcontext";
import { decodeJWT } from "../utils/utils";
import { useAPI } from "./useAPI";
import { v4 as uuidv4 } from 'uuid';

interface UserState {
    authenticated: boolean;
    userId: string | null;
    tokensLeft: number;
    anonymous: boolean;
    token: string | null;
}

export const useUser = () => {
    const { state } = React.useContext(AuthContext);

    const userState: UserState = {
        authenticated: state.token != null && state.userId != null && state.expirationTime != null && state.expirationTime > (new Date()).getTime(),
        userId: state.userId,
        tokensLeft: state.tokensLeft,
        anonymous: state.anonymous,
        token: state.token,
    }
    return userState;
}

export const RequireAuth = ({ children, disAllowAnonymous }: { children: JSX.Element, disAllowAnonymous?: boolean }) => {
    const user = useUser();
    const location = useLocation();
    const { dispatch } = React.useContext(AuthContext);
    const api = useAPI();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [redirect, setRedirect] = useState<string>("");
    useEffect(() => {
        // run only once
        const urlParams = new URLSearchParams(location.search);
        const sessionId = urlParams.get('sessionId');
        if (sessionId) {
            // try to login with the sessionId
            const t = async () => {
                try {
                    const loginResult = await api.slackPassthrough(sessionId);

                    const { userId, exp } = decodeJWT(loginResult.token!);

                    if (user.userId === userId) {
                        // okay, same user id now 
                        // navigate to the original page without the sessionId parameter
                        const urlParams = new URLSearchParams(location.search);
                        urlParams.delete('sessionId');
                        setRedirect(location.pathname + "?" + urlParams.toString() + "&k=" + uuidv4());
                    } else {
                        // do login
                        dispatch({ type: 'login', loginPayload: { userId: userId, token: loginResult.token!, expirationTime: exp * 1000, tokensLeft: loginResult.tokensLeft ? loginResult.tokensLeft as number : 0, anonymous: false } });
                    }
                } catch (e) {
                    // failed to login with sessionId
                    console.error("Failed to login with sessionId", e);
                    setErrorMessage("Error getting account information. Please try again later.");
                }
            }
            t();
        }
    }, [user.userId, api, dispatch, location.pathname, location.search]);

    // check if there is a sessionId parameter
    const urlParams = new URLSearchParams(location.search);
    const sessionId = urlParams.get('sessionId');
    if (sessionId) {
        if (errorMessage) {
            return <div>{errorMessage}</div>;
        } else {
            if (redirect) {
                return <Navigate to={redirect} />
            } else {
                return <div id="loading-main">
                    <div id="loading-anim">
                        <div className="cssload-loading">
                            <i></i>
                            <i></i>
                            <i></i>
                            <i></i>
                            <div style={{ textAlign: 'center', color: 'black', fontSize: '20px', marginTop: '20px', marginLeft: '-160px' }}>
                                Please wait while logging you in...
                            </div>
                        </div>
                    </div>
                </div>
                    ;
            }
        }
    } else {
        if (disAllowAnonymous && user.anonymous) {
            return <Navigate to={"/login?type=signup&redirect=" + encodeURIComponent(location.pathname + location.search)} state={{ from: location }} />
        }
        else if (user.authenticated) {
            return <div>{children}</div>;
        } else {
            return <Navigate to={"/login?type=signup&redirect=" + encodeURIComponent(location.pathname + location.search)} state={{ from: location }
            } />
        }
    }
}

